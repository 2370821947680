import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
// import * as styles from '../styles/hundredTop.module.scss'
import Layout from '../components/Layout/Layout'
import Seo from '../components/Seo/Seo'
import HeroHeader from '../components/HundredTop/HeroHeader/HeroHeader'
import InfoSection from '../components/HundredTop/InfoSection/InfoSection'
import TopMultiSection from '../components/HundredTop/TopMultiSection/TopMultiSection'
import TopCTA from '../components/HundredTop/TopCTA/TopCTA'
import TableHeader from '../components/HundredTop/TableHeader/TableHeader'
import ResultsTable from '../components/HundredTop/ResultsTable/ResultsTable'
import ListOneYear from '../components/HundredTop/ListOneYear/ListOneYear'
import BadgePanel from '../components/HundredTop/BadgePanel/BadgePanel'
import ListOneYearPlaceholder from '../components/HundredTop/ListOneYear/Placeholder/ListOneYearPlaceholder'
import { useState } from 'react'
import { useRef } from 'react'
import MarketoPopup from '../components/MarketoPopup/MarketoPopup'
import AnnouncementPopup from '../components/AnnouncementPopup/AnnouncementPopup'
import MapSection from '../components/HundredTop/MapSection/MapSection'
import HoverCardSection from '../components/HoverCardSection/HoverCardSection'
import Carousel from '../components/HundredTop/Carousel/Carousel'
import GraphicList from '../components/HundredTop/GraphicList/GraphicList'
import ThemedTextBlock from '../components/HundredTop/ThemedTextBlock/ThemedTextBlock'

export const query = graphql`
  query HundredTopQuery {
    allSanityTopHundred {
      nodes {
        showElement
        slug {
          current
        }
        browserTitle
        seoData {
          title
          description
          image {
            asset {
              url
              title
            }
          }
        }
        headerTicker {
          title
          _rawAnnouncements
          button {
            buttonText
            showArrow
            buttonContext
            buttonData
            buttonFile {
              asset {
                url
              }
            }
          }
        }
        header {
          _rawTitle
          _rawSubheader
          companyName
          slogan
          buttonOptions {
            buttonText
            buttonFile {
              asset {
                url
                title
              }
            }
            buttonData
            buttonContext
          }
          topLogo {
            asset {
              url
              title
            }
          }
          backgroundImage {
            asset {
              url
              title
            }
          }
        }
        content {
          ... on SanityGraphicList {
            _type
            title
            _rawDescription
            graphics {
              image
              _rawDescription
            }
          }
          ... on SanityTableHeader {
            _type
            titleHeader
            title
          }
          ... on SanityTable {
            _type
            tableData
          }
          ... on SanityCarousel {
            _type
            items {
              titleIcon {
                asset {
                  url
                }
              }
              title
              _rawDescription
              scrollIcon
              backgroundImage {
                asset {
                  url
                }
              }
            }
          }
          ... on SanityBadgePanel {
            _type
            title
            subtitle
            badges {
              showElement
              title
              image {
                asset {
                  url
                }
              }
              badgeLink
              button {
                buttonText
                buttonData
                buttonContext
                buttonFile {
                  asset {
                    url
                    title
                  }
                }
              }
            }
          }
          ... on SanityHoverCardSection {
            _type
            image {
              asset {
                url
              }
            }
            cards {
              textColor
              heading
              description
              borderColor
              icon {
                asset {
                  url
                }
              }
              backgroundImage {
                asset {
                  url
                }
              }
            }
          }
          ... on SanityThemedTextBlock {
            _type
            _rawText
          }
          ... on SanityListOneYear {
            _type
            badge {
              asset {
                url
              }
            }
            tableData
            year
            categories {
              categoryQuery
              categoryTitle
              _rawDescription
              buttonText
            }
            _rawFootnote
            ctaTitle
            ctaSubtitle
            ctaButton {
              buttonText
              buttonData
              buttonContext
              buttonFile {
                asset {
                  url
                  title
                }
              }
            }
            ctaButton2 {
              buttonText
              buttonData
              buttonContext
              buttonFile {
                asset {
                  url
                  title
                }
              }
            }
          }
          ... on SanityListOneYearPlaceholder {
            _type
            badge {
              asset {
                url
              }
            }
            title
            subtitle
            _rawDescription
            ctaTitle
            ctaSubtitle
            ctaButton {
              buttonText
              buttonData
              buttonContext
              buttonFile {
                asset {
                  url
                  title
                }
              }
            }
            ctaButton2 {
              buttonText
              buttonData
              buttonContext
              buttonFile {
                asset {
                  url
                  title
                }
              }
            }
          }
          ... on SanityTopMultiSection {
            _type
            title
            _rawDescription
            sections {
              sectionTitle
              sectionHeader
              _rawSectionDescription
              sectionImage {
                asset {
                  url
                  title
                }
              }
              button {
                buttonText
                buttonData
                buttonContext
                buttonFile {
                  asset {
                    url
                    title
                  }
                }
              }
            }
          }
          ... on SanityInfoSection {
            _type
            _rawDescription
            title
            imageAlignment
            textHeader
            image {
              asset {
                url
                title
              }
            }
            videoLink
            button {
              buttonText
              buttonData
              buttonContext
              buttonFile {
                asset {
                  url
                  title
                }
              }
            }
            button2 {
              buttonText
              buttonData
              buttonContext
              buttonFile {
                asset {
                  url
                  title
                }
              }
            }
          }
          ... on SanityTopCTA {
            _type
            title
            _rawDescription
            button {
              buttonText
              buttonData
              buttonContext
              buttonFile {
                asset {
                  url
                  title
                }
              }
            }
          }
          ... on SanityMapSection {
            _type
            title
            _rawSubtitle
            buttons {
              buttonText
              showArrow
              buttonContext
              buttonData
              buttonFile {
                asset {
                  url
                }
              }
            }
            button {
              buttonText
              showArrow
              buttonContext
              buttonData
              buttonFile {
                asset {
                  url
                }
              }
            }
            button2 {
              buttonText
              showArrow
              buttonContext
              buttonData
              buttonFile {
                asset {
                  url
                }
              }
            }
            defaultType
          }
        }
        hashForm {
          hash
          hashFormId
        }
        announcement {
          showElement
          title
          subtitle
          button {
            buttonText
            buttonData
            buttonContext
            buttonFile {
              asset {
                url
                title
              }
            }
          }
          image {
            asset {
              url
            }
          }
          altText
        }
      }
    }
    allSanityArticle(
      filter: {topics: {elemMatch: {title: {eq: "100 Top Hospitals®"}}}}
      limit: 3
      sort: {order: DESC, fields: date}
    ) {
      nodes {
        singlePhoto {
          asset {
            url
          }
        }
        slug {
          current
        }
        title
        date(formatString: "MMMM D, YYYY")
      }
    }
  }
`

const HundredTopTemplate = (props) => {
  const { data, pageContext } = props
  const [announcementIsOpen, setAnnouncementIsOpen] = useState(false);
  const [hashFormIsOpen, setHashFormIsOpen] = useState(false);
  const containerRef = useRef()
  const pageData = data.allSanityTopHundred.nodes.find(
    (topHundred) => topHundred.slug.current === pageContext.hundredTopSlug.current
  )
  const articles = data.allSanityArticle.nodes || [];
  // console.log(articles);
  // console.log(pageData);

  useEffect(() => {
    // Add the class to the body
    document.body.classList.add('white-bg')

    // Remove the class when the component unmounts
    return () => {
      document.body.classList.remove('white-bg')
    }
  }, [])

  useEffect(() => {
    if(window.location.hash && window.location.hash === pageData?.hashForm?.hash) {
      setHashFormIsOpen(true);
    }
    if(pageData.announcement) {
      setAnnouncementIsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Layout>
        {pageData.header && <HeroHeader data={pageData.header} themeContext="hundredTop" pageContext={pageContext} ticker={pageData?.headerTicker} route={pageData.slug.current} />}
        <>
          {pageData.content.map((block, index) => (
            <React.Fragment key={index}>
              {block._type === 'badgePanel' && <BadgePanel data={block} />}
              {block._type === 'hoverCardSection' && <HoverCardSection data={block}/>}
              {block._type === 'tableHeader' && <TableHeader data={block} />}
              {block._type === 'table' && <ResultsTable data={block} />}
              {block._type === 'listOneYear' && <ListOneYear data={block} />}
              {block._type === 'listOneYearPlaceholder' && <ListOneYearPlaceholder data={block} />}
              {block._type === 'infoSection' && <InfoSection data={block} newsData={articles} pageContext={pageContext} />}
              {block._type === 'mapSection' && <MapSection data={block} />}
              {block._type === 'topMultiSection' && <TopMultiSection data={block} />}
              {block._type === 'topCTA' && <TopCTA data={block} />}
              {block._type === 'carousel' && <Carousel data={block}/>}
              {block._type === 'graphicList' && <GraphicList data={block}/>}
              {block._type === 'themedTextBlock' && <ThemedTextBlock data={block}/>}
            </React.Fragment>
          ))}
        </>
        {pageData?.announcement && pageData?.announcement?.showElement &&
          <AnnouncementPopup
            data={pageData.announcement}
            isOpen={announcementIsOpen}
            setIsOpen={setAnnouncementIsOpen}
            appContainerRef={containerRef.current}
          />
        }
        {pageData?.hashForm?.hash && pageData?.hashForm?.hashFormId &&
          <MarketoPopup
            isOpen={hashFormIsOpen}
            setIsOpen={setHashFormIsOpen}
            marketoFormID={pageData.hashForm.hashFormId}
          />
        }
        {/* <WelcomePopup
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        appContainerRef={containerRef.current} /> */}
      </Layout>
    </>
  )
}

export default HundredTopTemplate

export const Head = (props) => {
  const { data, pageContext } = props
  let { hundredTopSlug } = pageContext
  const pageData = data.allSanityTopHundred.nodes.find(
    (topHundred) => topHundred.slug.current === hundredTopSlug.current
  )
  return <Seo title={pageData.browserTitle} seo={pageData.seoData} />
}
