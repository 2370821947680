// extracted by mini-css-extract-plugin
export var category = "OrderedListSection-module--category--df46a";
export var categoryContainer = "OrderedListSection-module--categoryContainer--c873a";
export var city = "OrderedListSection-module--city--39380";
export var columnsContainer = "OrderedListSection-module--columnsContainer--dd7c5";
export var container = "OrderedListSection-module--container--0c677";
export var dash = "OrderedListSection-module--dash--bb583";
export var description = "OrderedListSection-module--description--41f27";
export var everestAward = "OrderedListSection-module--everestAward--0b224";
export var listItem = "OrderedListSection-module--listItem--64954";
export var rank = "OrderedListSection-module--rank--7453e";