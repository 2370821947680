// define default data for NewsFeed component
import image1 from '../../../assets/images/hundred-top/100-top-newsfeed-1.jpg'
import image2 from '../../../assets/images/hundred-top/100-top-newsfeed-2.jpg'
import image3 from '../../../assets/images/hundred-top/100-top-newsfeed-3.jpg'

const defaultData = {
  title: '100 TOP NEWSFEED',
  button: {
    text: 'READ MORE',
    buttonContext: 'link',
    link: '/news',
  },
  articles: [
    {
      title: 'Premier’s PINC AI™ Clinical Intelligence: A Key to Reducing Clinical Variation and Improving Quality at St. Luke’s University Health Network',
      date: 'June 1, 2021',
      singlePhoto: {
        asset: {
          url: image1
        }
      }
    },
    {
      title: 'Premier’s PINC AI™ Clinical Intelligence: A Key to Reducing Clinical Variation and Improving Quality at St. Luke’s University Health Network',
      date: 'June 1, 2021',
      singlePhoto: {
        asset: {
          url: image2
        }
      }
    },
    {
      title: 'Premier’s PINC AI™ Clinical Intelligence: A Key to Reducing Clinical Variation and Improving Quality at St. Luke’s University Health Network',
      date: 'June 1, 2021',
      singlePhoto: {
        asset: {
          url: image3
        }
      }
    },
  ],
}


export default defaultData

