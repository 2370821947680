import React from "react";
import * as styles from './TableHeader.module.scss';

const TableHeader = (props) => {
    const { data } = props
    return (
        <>
        <div className={styles.tableHeaderContainer}>
            <h2>{data.titleHeader}</h2>
            <h1>{data.title}</h1>
        </div>
        </>
    )
}

export default TableHeader