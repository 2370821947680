// extracted by mini-css-extract-plugin
export var buttonContainer = "ListOneYear-module--buttonContainer--f3b55";
export var ctaContainer = "ListOneYear-module--ctaContainer--944b9";
export var ctaSubtitle = "ListOneYear-module--ctaSubtitle--0afc3";
export var ctaTitle = "ListOneYear-module--ctaTitle--b5ad6";
export var data = "ListOneYear-module--data--f38cd";
export var defaultSpacing = "ListOneYear-module--defaultSpacing--f3589";
export var footnoteContainer = "ListOneYear-module--footnoteContainer--a5aff";
export var footnoteSpacing = "ListOneYear-module--footnoteSpacing--7fe1b";
export var headerTextContainer = "ListOneYear-module--headerTextContainer--5fecc";
export var imageContainer = "ListOneYear-module--imageContainer--b936f";
export var list = "ListOneYear-module--list--a2a70";
export var listOneYearContainer = "ListOneYear-module--listOneYearContainer--6749a";
export var listOneYearContent = "ListOneYear-module--listOneYearContent--445b5";
export var scrollButton = "ListOneYear-module--scrollButton--158c0";
export var scrollButtonContainer = "ListOneYear-module--scrollButtonContainer--eae52";
export var scrollButtonsTitle = "ListOneYear-module--scrollButtonsTitle--084b4";
export var year = "ListOneYear-module--year--62195";