import React from 'react';
import Modal from 'react-modal'
import * as styles from './AnnouncementPopup.module.scss'
import ButtonTemplate from '../AppliedSciences/ASComponents/buttonTemplate/buttonTemplate';

const modalStyles = {
  content: {
    top: '45%',
    left: '50%',
    right: 'auto',
    bottom: '25%',
    paddingTop: 0,
    marginRight: '-50%',
    paddingBottom: 50,
    transform: 'translate(-50%, -45%)',
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: 0,
    padding: 0,
    overflow: 'visible',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.71)',
    zIndex: 400
  },
};



const AnnouncementPopup = ({ data, isOpen, setIsOpen, appContainerRef }) => {

  // important for users of screenreaders that other page content be hidden
  Modal.setAppElement(appContainerRef)
  // console.log(data);
  return (
    <Modal
      closeTimeoutMS={200} // needs to match css transition duration
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      style={modalStyles}
      onAfterOpen={() => (document.body.style.overflow = 'hidden')}
      onAfterClose={() => (document.body.style.overflow = 'unset')}
      >
      <div className={`${styles.container}`}>
        <div className={styles.imageContainer}>
            <img src={data.image.asset.url} alt={data.altText || 'announcement icon'}/>
        </div>
        <div className={styles.textContainer}>
            <h2 className={styles.title}>{data.title}</h2>
            <h3 className={styles.subtitle}>{data.subtitle}</h3>
            <div className={styles.buttonContainer}>
              <ButtonTemplate data={data.button}>
                  <span>
                      {data.button.buttonText}
                  </span>
              </ButtonTemplate>
            </div>
            <button
            type="button"
            className={`${styles.modalclosebutton}`}
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setIsOpen(false)}
            >
            <svg
                fill="none"
                height="36"
                viewBox="0 0 44 44"
                width="36"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g stroke="#000000" strokeWidth="2">
                <path d="m15.7174 15.7175 13.435 13.435" />
                <path d="m15.7177 29.1525 13.435-13.435" />
                </g>
            </svg>
            </button>
        </div>
      </div>
    </Modal>
  )
};

export default AnnouncementPopup;
