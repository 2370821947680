import React from 'react'
import * as styles from './ThemedTextBlock.module.scss'
import { PortableText } from '@portabletext/react'

// Renders lists ordered by rank for each category

const ThemedTextBlock = (props) => {
  const { data } = props

  return (
    <section className={styles.themedTextContainer}>
      <PortableText value={data._rawText}/>
    </section>
  )
}

export default ThemedTextBlock
