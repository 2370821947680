import React, { useEffect, useRef } from 'react'

/* 
    This component will run the setIsVisible function prop when entering the viewport
    prop: 
    setIsVisible - function toggled true when this component enters the viewport, toggled false on exit
    setIsVisibleOnce - function set to true once, the first time this component enters the viewport
*/

function ScrollObserver(props) {

    const {
      setIsVisible = () => null,
      setIsVisibleOnce = () => null,
      setBoundingRect = () => null,
      threshold = 0,
      rootMargin = '0px'
    } = props

    const domRef = useRef()

    useEffect(() => {
        const ref = domRef.current

        const options = {
            // threshold: 0,
            // rootMargin: '-50% 0px',
            threshold: threshold,
            rootMargin: rootMargin
        }

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisibleOnce(true)
                    setBoundingRect(entry.boundingClientRect)
                }
                setIsVisible(entry.isIntersecting)
            })
        }, options)

        observer.observe(ref)

        return () => observer.unobserve(ref)

    }, [setIsVisible, setIsVisibleOnce, setBoundingRect, threshold, rootMargin])


    return (
        <div ref={domRef}>
            {props.children}
        </div>
    )
}

export default ScrollObserver
