import { PortableText } from '@portabletext/react'
import React from 'react'
import ButtonTemplate from '../../AppliedSciences/ASComponents/buttonTemplate/buttonTemplate'
import DownloadArrow from '../../svg/DownloadArrow'
import useMediaQuery from '../../../utils/useMediaQuery'
import EbookArrow from '../../svg/EbookArrow'
import NewsFeed from '../NewsFeed/NewsFeed'
import * as styles from './InfoSection.module.scss'

const InfoSection = (props) => {
  const { data, newsData, pageContext } = props;
  const isMobile = useMediaQuery('(max-width: 900px)')
  const isHundredTopHome = pageContext?.hundredTopSlug.current === 'home';
  const sectionClassNames = `
    ${styles.infoContainer}
    ${data?.imageAlignment === 'right' ? styles.rightAlign : ''}
    ${isHundredTopHome ? styles.home : ''}
    ${isHundredTopHome && data?.textHeader.includes('100 TOP') ? styles.infoContainerSpacing : ''}
    ${data.videoLink ? styles.videoSection : ''}
  `
  // console.log(data);
  return (
    <section className={sectionClassNames}>
      {((!isMobile || data?.imageAlignment !== 'right') && data.image) && (
        <div className={`${styles.imageContainer} ${data?.textHeader.includes('Overview') || data?.textHeader.includes('Trust') ? styles.badge : ''}`}>
          {data.videoLink ? 
              <a href={data.videoLink} target='_blank' rel="noreferrer noopener">
                <img src={data?.image?.asset.url} alt="100 Top Logo" />
              </a>
            :
              <img src={data?.image?.asset.url} alt="100 Top Logo" />  
            }
        </div>
      )}
      <div className={styles.textContainer}>
        <h2 className={styles.header}>{data?.textHeader}</h2>
        <h3 className={styles.title}>{data?.title}</h3>
        {isMobile && data?.imageAlignment === 'right' && data.image && (
          <div className={`${styles.imageContainer} ${styles.mobile}`}>\
            {data.videoLink ? 
                 <a href={data.videoLink} target='_blank' rel="noreferrer noopener">
                 <img src={data?.image?.asset.url} alt="100 Top Logo" />
                </a>
            :
              <img src={data?.image?.asset.url} alt="100 Top Logo" />  
            }
          </div>
        )}
        <div className={styles.descriptionContainer + (!data.image ? " " + styles.fullDescription : '')}>
          <PortableText value={data?._rawDescription} />
        </div>
        <div className={styles.buttonContainer}>
          <ButtonTemplate data={data?.button}>
            {data?.button?.buttonText && (
              <>
                {data.button.buttonText}
                {(data.button.buttonContext === 'link' || data.button.buttonContext === 'video') && <EbookArrow />}
                {data.button.buttonContext === 'file' && <DownloadArrow />}
              </>
            )}
          </ButtonTemplate>
          {data?.button2 && data?.button2?.buttonText &&
            <ButtonTemplate data={data?.button2}>
              {data?.button2?.buttonText && (
                <>
                  {data.button2.buttonText}
                  {(data.button2.buttonContext === 'link' || data.button2.buttonContext === 'video') && <EbookArrow />}
                  {data.button2.buttonContext === 'file' && <DownloadArrow />}
                </>
              )}
            </ButtonTemplate>
          }
        </div>
      </div>
      <div>
        {isHundredTopHome && data?.textHeader.includes('Overview') && <NewsFeed data={newsData} />}
      </div>
    </section>
  )
}

export default InfoSection
