import React from 'react'
import defaultData from './defaultData'
import ArrowRounded from '../../svg/ArrowRounded'
import Link from '../../Link/Link'
// import { StaticImage } from "gatsby-plugin-image"
import * as styles from './NewsFeed.module.scss'

const NewsFeed = (props) => {
  const { data = defaultData } = props;
  // console.log(data[0]);
  // const { title, button, articles } = data
  
  return (
    <div className={styles.container}>
      <h2 className={styles.headerTitle}>{'100 Top Newsfeed'}</h2>
      <div className={styles.articles}>
        {data.map((article, index) => (
          <Link to={'/news/' + article.slug.current} key={article.title}>
            <div className={styles.article} key={index}>
              <div className={styles.imageContainer}>
                <img src={article.singlePhoto?.asset?.url} alt={article.title} />
              </div>
              <div className={styles.textContainer}>
                <p className={styles.date}>{article?.date}</p>
                <div className={styles.articleTitleWrapper}>
                  <p className={styles.articleTitle}>{article?.title}</p>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
      <div className={styles.footer}>
        <Link to={'/news/topic/100-top-hospitals'}>
          {'Read More'} <ArrowRounded width="11" />
        </Link>
      </div>
    </div>
  )
}

export default NewsFeed
