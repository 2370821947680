import React, { useState, useEffect } from 'react'
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { collection, getDocs } from 'firebase/firestore'
import SortableTable from '../SortableTable/SortableTable'

// this component gets data from firestore and passes it to the SortableTable component
// the type is set in SANITY and passed to this component as a prop
// the type prop determines which collection is queried from firestore
// possible types are:
//  * 50TopCardio
//  * 100TopHospitals
//  * 15TopHealth

const firebaseConfig = {
  apiKey: 'AIzaSyAV4fgBaFzhYi5_w46zijUx_9Y4t8siWU0',
  authDomain: 'pinc-ai-phase2.firebaseapp.com',
  projectId: 'pinc-ai-phase2',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app)

const ResultsTable = (props) => {
  // ToDo - change tableData to type, tableType or tableDataType
  const { tableData = '50TopCardio' } = props.data

  // the type is also used to set the collection name
  const collectionName = tableData

  const [rankings, setRankings] = useState([])

  useEffect(() => {
    const getList = async () => {
      const querySnapshot = await getDocs(collection(db, collectionName))
      const firestoreData = querySnapshot.docs.map((doc) => doc.data())
      // join array of yearsWon into comma separated string
      firestoreData.forEach((item) => {
        item.yearsWon = item.yearsWon.join(', ')
      })
      setRankings(firestoreData)
      // console.log(firestoreData)
    }

    getList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <SortableTable data={rankings} />
}

export default ResultsTable
