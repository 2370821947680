import React, { useEffect, useState, useRef } from 'react'
import * as styles from './HeaderTicker.module.scss'
import ButtonTemplate from '../../AppliedSciences/ASComponents/buttonTemplate/buttonTemplate'
import { PortableText } from '@portabletext/react'
import { gsap } from 'gsap'
import { useGSAP } from '@gsap/react'

const HeaderTicker = (props) => {
  const { data } = props

  // Check if the component has already been shown in this session
  // const [hasBeenShown, setHasBeenShown] = useState(true)

  // useEffect(() => {
  
  //   // Check if the component has already been shown in this session
  //   const alreadyShown = sessionStorage.getItem('tickerShown') === 'true' ? true : false
  
  //   setHasBeenShown(alreadyShown)
  // }, [])


  // number of times the animation has completed
  const [IterationCount, setIterationCount] = useState(0)

  const mainListRef = useRef(null)

  // setup listener for the animationiteration event on the mainList element
  useEffect(() => {
    const mainListElement = mainListRef.current

    if (!mainListElement) {
      return
    }

    const handleAnimationIteration = () => {
      setIterationCount(IterationCount + 1)
      // console.log('animation iteration completed')
    }

    mainListElement.addEventListener('animationiteration', handleAnimationIteration)

    // Clean up function to remove the event listener when the component unmounts
    return () => {
      mainListElement.removeEventListener('animationiteration', handleAnimationIteration)
    }
  }, [IterationCount])

  //  GSAP context safe hook to ensure that the animation is not run after the component has unmounted
  const { contextSafe } = useGSAP()

  // setup the animation to hide the ticker
  const hideAnimation = contextSafe(() => {
    gsap.to(mainListRef.current, { opacity: 0, duration: 1.5, ease: 'Power3.easeOut' })
    gsap.to(mainListRef.current, { maxHeight: 0, duration: 1.5, delay: 0, ease: 'Power3.easeOut' }) // 75% of 1.5s
  })
  
  // set number of times the animation will run before hiding
  const maxIterations = 2
  const AnimationIsDone = IterationCount >= maxIterations

  // run the animations
  useEffect(() => {
    if (AnimationIsDone) {
      hideAnimation()
      // sessionStorage.setItem('tickerShown', 'true')
    } else {
      if (mainListRef.current) {
        mainListRef.current.style.opacity = 1
        mainListRef.current.style.maxHeight = '1000px'
      }
    }
  }, [AnimationIsDone, hideAnimation])

  // don't render the component if it has already been shown
  // if (hasBeenShown) {
  //   return null
  // }

  return (
    <div className={styles.container} ref={mainListRef}>
      <div className={styles.titleContainer}>
        <h3>{data.title}</h3>
      </div>
      <div className={styles.listContainer}>
        <ul className={styles.mainList}>
          {data._rawAnnouncements.map((announcement, index) => (
            <li key={index}>
              <PortableText value={announcement.textEditor} />
            </li>
          ))}
        </ul>
        <ul className={styles.duplicateList}>
          {data._rawAnnouncements.map((announcement, index) => (
            <li key={index}>
              <PortableText value={announcement.textEditor} />
            </li>
          ))}
        </ul>
      </div>
      {data.button && (
        <div className={styles.buttonContainer}>
          <ButtonTemplate data={data.button}>{data.button.buttonText}</ButtonTemplate>
        </div>
      )}
    </div>
  )
}

export default HeaderTicker
