// extracted by mini-css-extract-plugin
export var active = "Carousel-module--active--2f053";
export var activeBorder = "Carousel-module--activeBorder--d021f";
export var backgroundImage = "Carousel-module--backgroundImage--94a81";
export var carouselContainer = "Carousel-module--carouselContainer--39b9d";
export var carouselPages = "Carousel-module--carouselPages--8618a";
export var carouselSlide = "Carousel-module--carouselSlide--521a2";
export var descriptionText = "Carousel-module--descriptionText--f4005";
export var icon = "Carousel-module--icon--72957";
export var page = "Carousel-module--page--c497a";
export var pageIcon = "Carousel-module--pageIcon--4895a";
export var slideText = "Carousel-module--slideText--d5020";
export var title = "Carousel-module--title--84baf";