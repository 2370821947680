import React from 'react'
import * as styles from './ListOneYearPlaceholder.module.scss'
import { PortableText } from '@portabletext/react'
import ButtonTemplate from '../../../AppliedSciences/ASComponents/buttonTemplate/buttonTemplate'
import EbookArrow from '../../../svg/EbookArrow'
import DownloadArrow from '../../../svg/DownloadArrow'

const ListOneYearPlaceholder = (props) => {
  const { data } = props
  // let dataTitle = ''

  // const year = data.year

  // switch (data.tableData) {
  //   case 'Top15Health':
  //     dataTitle = '15 Top Health Systems™'
  //     break
  //   case 'Top50Cardio':
  //     dataTitle = '50 Top Cardiovascular Hospitals™'
  //     break
  //   case 'Top100Hospitals':
  //     dataTitle = '100 Top Hospitals®'
  //     break
  //   default:
  //     dataTitle = '100 Top Hospitals®'
  //     break
  // }
  // console.log(data);
  return (
    <>
      <div className={styles.listOneYearContainer}>
        <div className={styles.listOneYearContent}>
          <div className={styles.imageContainer}>
            <img src={data.badge.asset.url} alt={data.tableData} />
          </div>
          <div className={styles.headerTextContainer}>
            <h3 className={styles.title}>{data.title}</h3>
            <h3 className={styles.subtitle}>{data.subtitle}</h3>
          </div>
          <div className={styles.descriptionContainer}>
            <PortableText value={data?._rawDescription} />
          </div>
          <div className={styles.ctaContainer}>
            <h3 className={styles.ctaTitle}>{data.ctaTitle}</h3>
            <p className={styles.ctaSubtitle}>{data.ctaSubtitle}</p>
            <div className={styles.buttonContainer}>
                <ButtonTemplate data={data?.ctaButton}>
                    <p>{data?.ctaButton?.buttonText}</p>
                    <EbookArrow/>
                </ButtonTemplate>
                {data.ctaButton2 &&
                  <ButtonTemplate data={data?.ctaButton2}>
                    <p>{data?.ctaButton2?.buttonText}</p>
                    <DownloadArrow/>
                  </ButtonTemplate>
                }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ListOneYearPlaceholder
