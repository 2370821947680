// extracted by mini-css-extract-plugin
export var active = "TopMultiSection-module--active--09911";
export var buttonContainer = "TopMultiSection-module--buttonContainer--f89c6";
export var container = "TopMultiSection-module--container--31072";
export var downArrow = "TopMultiSection-module--downArrow--2973c";
export var headerDescription = "TopMultiSection-module--headerDescription--ea567";
export var imageContainer = "TopMultiSection-module--imageContainer--b3c77";
export var mobileButton = "TopMultiSection-module--mobileButton--b6da5";
export var sectionContainer = "TopMultiSection-module--sectionContainer--7c019";
export var showArrow = "TopMultiSection-module--showArrow--de00b";
export var solutionBlock = "TopMultiSection-module--solutionBlock--82657";
export var solutionExpanded = "TopMultiSection-module--solutionExpanded--7ff06";
export var solutionTextContainer = "TopMultiSection-module--solutionTextContainer--921ca";
export var stacked = "TopMultiSection-module--stacked--f8d8f";
export var textContainer = "TopMultiSection-module--textContainer--d8bc9";
export var textMobile = "TopMultiSection-module--textMobile--9998e";