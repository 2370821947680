import React, { useRef, useEffect } from 'react'
import * as styles from './GraphicList.module.scss'
import { PortableText } from '@portabletext/react'
import gsap from 'gsap'
import { useGSAP } from '@gsap/react'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(useGSAP)
gsap.registerPlugin(ScrollTrigger)

// reset the opacity for all paths
const resetRedPaths = () => {
  // Reset the opacity of all paths with a gray fill
  gsap.set('#top100graphic5 .graphic5-gray', {
    opacity: 1,
  })
  // Reset the opacity of all plus signs
  gsap.set('#top100graphic5 .graphic5-gray-plus', {
    opacity: 1,
  })
  // Reset the opacity of the entire graphic
  gsap.set('#top100graphic5', {
    opacity: 1,
  })
}

// fade out the gray paths in a staggered fashion
const removeGrayPaths = () => {
  // Randomly fade out all paths with a gray fill
  gsap.to('#top100graphic5 .graphic5-gray', {
    duration: 0.05,
    opacity: 0,
    ease: 'power2.in',
    stagger: {
      each: 0.2,
      from: 'random',
      onStart: function () {
        // Fade out the associated .graphic5-gray-plus path instantly when each staggered animation starts
        gsap.to('#top100graphic5 .graphic5-gray-plus', {
          duration: 0,
          opacity: 0,
        })
      },
    },
  })
}

const GraphicList = (props) => {
  const { data } = props
  const ref = useRef(null)
  const initialAnimationsComplete = useRef(false)

  useGSAP(() => {
    // Animation for the top 100 dot grid graphic
    let animation1 = gsap.from(
      '#top100graphic1 circle',
      {
        duration: 0.4,
        opacity: 0,
        ease: 'power1.out',
        stagger: {
          each: 0.004,
          from: 'random',
          grid: 'auto',
        },
        scrollTrigger: {
          trigger: '#top100graphic1',
          start: 'top 90%', // when the top of the trigger hits 10% from bottom of the viewport
          end: 'bottom top', // when the bottom of the trigger hits the top of the viewport
          scrub: false,
          // markers: true,
          toggleActions: 'restart none none none', // onEnter, onLeave, onEnterBack, onLeaveBack
        },
      },
      { scope: ref }
    )

    // reset animation on enter of the viewport
    ScrollTrigger.create({
      trigger: '#top100graphic1',
      start: 'top bottom',
      onEnter: () => {
        if (initialAnimationsComplete.current) {
        animation1.pause(0)
        }
      },
    })


    // Animation for the top 100 bar graph
    let animation2 = gsap.fromTo(
      '#top100graphic2 .clip-rect',
      {
        attr: {width: '0%'}, // Start with a width of 0
      },
      {
        duration: 2,
        attr: { width: '100%' }, // Reveal the full width
        ease: 'power1.out',
        stagger: {
          each: 0.3,
          from: 'start',
        },
        scrollTrigger: {
          trigger: '#top100graphic2',
          start: 'top 70%', 
          end: 'bottom top', 
          scrub: false,
          toggleActions: 'restart none none none', // onEnter, onLeave, onEnterBack, onLeaveBack
        },
      },
      { scope: ref }
    )

      // reset animation on enter of the viewport
    ScrollTrigger.create({
      trigger: '#top100graphic2',
      start: 'top bottom',
      onEnter: () => {
        if (initialAnimationsComplete.current) {
          animation2.pause(0)
        }
      },
    })


    // Animation for the top 100 money jar graphic
    let animation3 = gsap.from(
      '#top100graphic3 #jar-path',
      {
        duration: 2,
        y: '100%',
        ease: 'power1.out',
        scrollTrigger: {
          trigger: '#top100graphic3',
          start: 'top 70%', 
          end: 'bottom top', 
          scrub: false,
          toggleActions: 'restart none none none', // onEnter, onLeave, onEnterBack, onLeaveBack
        },
      },
      { scope: ref }
    )

    // reset animation on enter of the viewport
    ScrollTrigger.create({
      trigger: '#top100graphic3',
      start: 'top bottom',
      onEnter: () => {
        if (initialAnimationsComplete.current) {
          animation3.pause(0)
          }
      },
    })

    // Animation for the top 100 gear graphic
    let animation4 = gsap.fromTo(
      '#top100graphic4 #gear-fill-rect',
      {
        attr: {y: '100%'}, // Start with a width of 0
      },
      {
        duration: 3,
        attr: { y: '0%' },
        ease: 'power1.out',
        scrollTrigger: {
          trigger: '#top100graphic4',
          start: 'top 70%', 
          end: 'bottom top', 
          scrub: false,
          toggleActions: 'restart none none none', // onEnter, onLeave, onEnterBack, onLeaveBack
        },
      },
      { scope: ref }
    )

    // reset animation on enter of the viewport
    ScrollTrigger.create({
      trigger: '#top100graphic4',
      start: 'top bottom',
      onEnter: () => {
        if (initialAnimationsComplete.current) {
          animation4.pause(0)
          }
      },
    })

    // Animation for the top 100 ambulance graphic
    gsap.fromTo(
      '#top100graphic5',
      {
        opacity: 0,
      },
      {
        duration: 1,
        delay: 0.2,
        opacity: 1,
        ease: 'power1.out',
        onStart: resetRedPaths, // Call function to reset the red paths to full opacity
        onComplete: removeGrayPaths, // Call function to remove the red paths after the fade in animation completes
        scrollTrigger: {
          trigger: '#top100graphic5',
          start: 'top bottom', // when top of the trigger hits the bottom of the viewport
          end: 'bottom top', // when bottom of the trigger hits the top of the viewport
          scrub: false,
          toggleActions: 'restart none none none', // onEnter, onLeave, onEnterBack, onLeaveBack
        },
      },
      { scope: ref }
    )
  

  })


  // set initialAnimationsComplete to true after 0.5 seconds
  // this allows animations to run on page load, but prevents ScrollTrigger onEnter from firing
  useEffect(() => {
    gsap.delayedCall(0.5, () => {
      initialAnimationsComplete.current = true
    })
  }, [])

  return (
    <div className={styles.graphicListContainer} ref={ref}>
      <div className={styles.sectionHeader}>
        <h2>{data.title}</h2>
        <PortableText value={data._rawDescription.textEditor} />
      </div>
      <div className={styles.graphicsContainer}>
        {data.graphics.map((section, index) => (
          <div className={styles.section} key={index}>
            <div className={styles.image} dangerouslySetInnerHTML={{ __html: section.image }} />
            <div className={styles.sectionText}>
              <PortableText value={section._rawDescription} />
            </div>
          </div>
        ))}
      </div>
      <div className={styles.sectionFooter}>
        <PortableText value={data._rawDescription.footnote} />
      </div>
    </div>
  )
}

export default GraphicList
