import React, { useMemo } from 'react'
import { useTable, useSortBy, useGlobalFilter, usePagination } from 'react-table'
import Pagination from './Pagination/Pagination'
import Select, { components } from 'react-select'
import DownIndicator from '../../svg/DownIndicator'
import * as styles from './SortableTable.module.scss'

// react-table based table component

// react-select options for page size select

const options = [
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
]

// react-select dropdown component for page size select

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <DownIndicator />
    </components.DropdownIndicator>
  )
}

// react-select styles for :focus state - unable to get this to work

const customStyles = {
  control: (base, state) => ({
    ...base,
    outline: state.isFocused ? '1px solid #007F91' : 'none',
  }),
}

const SortableTable = (props) => {
  const data = useMemo(() => props.data, [props.data])

  const columns = useMemo(
    () => [
      {
        Header: 'Hospital',
        accessor: 'hospital',
      },
      {
        Header: 'City',
        accessor: 'city',
      },
      {
        Header: 'Total Years Won',
        accessor: 'totalYearsWon',
      },
      {
        Header: 'Years Won',
        accessor: 'yearsWon',
      },
    ],
    []
  )

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: 'hospital',
            desc: false,
          },
        ],
        pageSize: 10,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter,
    page,
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    pageCount,
    pageOptions,
    setPageSize,
  } = tableInstance
  // const { globalFilter } = state

  const handleSelect = (selectedOption) => {
    // console.log(selectedOption)
    setPageSize(Number(selectedOption.value))
  }

  return (
    <div className={styles.container}>
      <div className={styles.topControls}>
        {/* page size */}
        <div>
          {/* react-select */}
          <Select
            className={styles.select}
            classNames={{
              indicatorSeparator: () => styles.separator,
              valueContainer: () => styles.valueContainer,
              control: () => styles.control,
            }}
            styles={customStyles}
            options={options}
            defaultValue={options[0]}
            onChange={handleSelect}
            components={{ DropdownIndicator }}
          />
          <span> ENTRIES SHOWN</span>
        </div>
        {/* search input */}
        <div className={styles.search}>
          <span>SEARCH: &nbsp;</span>
          <div className={styles.searchInput}>
            <input value={globalFilter || ''} onChange={(e) => setGlobalFilter(e.target.value)} />
          </div>
        </div>
      </div>
      <div className={styles.tableWrapper}>
        <table {...getTableProps()} className={styles.table}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={column.isSorted ? (column.isSortedDesc ? 'desc' : 'asc') : ''}
                  >
                    {column.render('Header')}
                    {/* add a sort direction indicator */}
                    <span className={styles.sortIcon}>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <DownIndicator color="#007F91" />
                        ) : (
                          <DownIndicator color="#007F91" direction="up" />
                        )
                      ) : <DownIndicator color="#D5D5D5"/>}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      {/* pagination */}
      <div className={styles.bottomControls}>
        <div className={styles.rowsIndicator}>
          <strong>
            <span>SHOWING</span> {rows.length > 0 ? pageIndex * pageSize + 1 : '0'}
          </strong>{' '}
          TO {/* max will likely be less than (pageIndex + 1) * pageSize on the last partial page */}
          {/* {(pageIndex + 1) * pageSize > data.length ? data.length : (pageIndex + 1) * pageSize} of {data.length} entries */}
          <strong>{(pageIndex + 1) * pageSize > rows.length ? rows.length : (pageIndex + 1) * pageSize}</strong> OF{' '}
          <strong>{rows.length} ENTRIES</strong>
        </div>
        <div>
          <Pagination
            pageOptions={pageOptions}
            pageCount={pageCount}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            previousPage={previousPage}
            nextPage={nextPage}
            gotoPage={gotoPage}
            pageIndex={pageIndex}
          />
        </div>
      </div>
    </div>
  )
}

export default SortableTable
