// extracted by mini-css-extract-plugin
export var badge = "InfoSection-module--badge--3f0f0";
export var buttonContainer = "InfoSection-module--buttonContainer--2df8c";
export var descriptionContainer = "InfoSection-module--descriptionContainer--18d91";
export var fullDescription = "InfoSection-module--fullDescription--bb216";
export var header = "InfoSection-module--header--c9708";
export var home = "InfoSection-module--home--10f99";
export var imageContainer = "InfoSection-module--imageContainer--243f1";
export var infoContainer = "InfoSection-module--infoContainer--50275";
export var infoContainerSpacing = "InfoSection-module--infoContainerSpacing--b7fc7";
export var mobile = "InfoSection-module--mobile--17f5d";
export var rightAlign = "InfoSection-module--rightAlign--d45c5";
export var textContainer = "InfoSection-module--textContainer--311c0";
export var title = "InfoSection-module--title--aac10";
export var videoSection = "InfoSection-module--videoSection--25407";