import React, { useState, useEffect } from 'react'
import { initializeApp } from 'firebase/app'
import { getFirestore, orderBy } from 'firebase/firestore'
import { collection, query, where, getDocs } from 'firebase/firestore'
import StaticTable from '../StaticTable/StaticTable'
import * as styles from './ListOneYear.module.scss'
import { PortableText } from '@portabletext/react'
import ButtonTemplate from '../../AppliedSciences/ASComponents/buttonTemplate/buttonTemplate'
import EbookArrow from '../../svg/EbookArrow'
import DownloadArrow from '../../svg/DownloadArrow'
import OrderedListSection from '../OrderedListSection/OrderedListSection'
import slugify from '../../../utils/slugify'

// this component gets data from firestore and passes it to the StaticTable component
// the type is set in SANITY and passed to this component as a prop
// the tableData (type) determines which collection is queried from firestore
// possible types are:
//  * 50TopCardio
//  * 100TopHospitals
//  * 15TopHealth
// the year prop determines the query for firestore

const firebaseConfig = {
  apiKey: 'AIzaSyAV4fgBaFzhYi5_w46zijUx_9Y4t8siWU0',
  authDomain: 'pinc-ai-phase2.firebaseapp.com',
  projectId: 'pinc-ai-phase2',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app)

const ListOneYear = (props) => {
  const { data } = props
  let dataTitle = ''
  let categories = []
  data?.categories?.forEach((category) => {
    if (!categories.includes(category.categoryQuery)) {
      categories.push(category.categoryQuery)
    }
  })

  // the type is also used to set the collection name

  let collectionName = data.tableData
  const year = data.year

  // *** temporary fix to show 2024 in section title ***
  // *** the query for the year (2023) and the title (2024) do not match ***
  let displayedYear = year

  // *** temporary fix 15 top winners - cannot add winners to Top15Health collection until released ***
  // *** this will be removed when the 15 top winners are added to the Top15Health collection ***
  if (collectionName === 'Top15Health') {
    collectionName = 'Top15HealthWinners'
  }
  // *** temporary fix to show 2024 winners on staging ***
  // *** this can be removed after adding Top50CardioWinners2024 to list of options in sanity ***
  if (collectionName === 'Top50Cardio') {
    collectionName = 'Top50CardioWinners2024'
    // displayedYear = '2024'
  }
  //  *** end temporary fixes ***

  const [rankings, setRankings] = useState([])

  switch (data.tableData) {
    case 'Top50Cardio':
    case 'Top50CardioWinners2024':
    case 'Top50CardioWinners2025':
      dataTitle = '50 Top Cardiovascular Hospitals®'
      break
    case 'Top15Health':
    case 'Top15HealthWinners2024':
      dataTitle = '15 Top Health Systems®'
      break
    case 'EverestAward2023':
    case 'EverestWinners2025':
      dataTitle = 'Everest Award Winners'
      break
    case 'Top100Hospitals':
    case 'Top100Hospitals2022':
    case 'Top100Hospitals2025':
      dataTitle = '100 Top Hospitals®'
      break
    default:
      dataTitle = '100 Top Hospitals®'
      break
  }

  const customComponents = {
    marks: {
      footnote: ({ children }) => (
        <span>
          <sup>{children}</sup>
        </span>
      ),
    },
  }

  useEffect(() => {
    const queryForYear = query(
      collection(db, collectionName),
      where('yearsWon', 'array-contains', year),
      orderBy('hospital')
    )
    const getList = async () => {
      const querySnapshot = await getDocs(queryForYear)
      setRankings(querySnapshot.docs.map((doc) => doc.data()))
    }

    getList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // scroll to the category when the button is clicked
  const scrollToId = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const headerOffset = 150; // scroll to the top of the element minus 150px
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - headerOffset;
  
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  }

  return (
    <>
      <div className={styles.listOneYearContainer}>
        <div className={styles.listOneYearContent}>
          <div className={styles.imageContainer}>
            <img src={data.badge.asset.url} alt={data.tableData} />
          </div>
          <div className={styles.headerTextContainer}>
            <h3 className={styles.data}>Congratulations to the {dataTitle}</h3>
            <h3 className={styles.year}>
              {displayedYear} {data?.tableData === 'EverestWinners2024' && 'Everest'} Award Winners
            </h3>
            {rankings.length > 0 && data?.categories?.length ? (
              <>
                <p className={styles.scrollButtonsTitle}>Hospital Categories:</p>
              <div className={styles.scrollButtonContainer}>
                {data.categories.map((category, index) => (
                  <div key={index}>
                    {category.buttonText && category.categoryQuery && (
                      <button
                      className={styles.scrollButton}
                      onClick={() => scrollToId(slugify(category.categoryQuery || ''))}
                      >
                        {category.buttonText}
                      </button>
                    )}
                  </div>
                ))}
              </div>
                </>
            ) : (
              <h3 className={styles.list}>(Listed Alphabetically)</h3>
            )}
          </div>
          <div>
            {/* if ranking contains a category render the OrderedListSection */}
            {rankings.length > 0 &&
              (rankings[0]?.category && !data.tableData.includes('Everest') ? (
                <OrderedListSection data={rankings} queries={categories} sanityData={data.categories} />
              ) : (
                <StaticTable data={rankings} />
              ))}
          </div>
          {data._rawFootnote && (
            <div className={styles.footnoteContainer}>
              <PortableText value={data._rawFootnote} components={customComponents} />
            </div>
          )}
          {data?.ctaTitle && (
            <div
              className={
                styles.ctaContainer + (data._rawFootnote ? ' ' + styles.footnoteSpacing : ' ' + styles.defaultSpacing)
              }
            >
              <h3 className={styles.ctaTitle}>{data.ctaTitle}</h3>
              <p className={styles.ctaSubtitle}>{data.ctaSubtitle}</p>
              <div className={styles.buttonContainer}>
                {data.ctaButton && (
                  <ButtonTemplate data={data?.ctaButton}>
                    <p>{data?.ctaButton?.buttonText}</p>
                    <EbookArrow />
                  </ButtonTemplate>
                )}
                {data.ctaButton2 && (
                  <ButtonTemplate data={data?.ctaButton2}>
                    <p>{data?.ctaButton2?.buttonText}</p>
                    <DownloadArrow />
                  </ButtonTemplate>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default ListOneYear
