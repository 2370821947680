import React from 'react'
import ButtonTemplate from '../../AppliedSciences/ASComponents/buttonTemplate/buttonTemplate'
import MakeSuperscript from '../../MakeSuperscript/MakeSuperscript'
import * as styles from './BadgePanel.module.scss'

const BadgePanel = (props) => {
  const { data } = props

  // making <div> clickable and keyboard accessible with role="link" and tabIndex="0" and handlers

  const handleClick = (event, badgeLink) => {
    window.location.href = badgeLink;
  }

  const handleKeyDown = (event, badgeLink) => {
    if (event.key === 'Enter') {
      window.location.href = badgeLink;
    }
  }

  const buttonClick = (event, badgeLink) => {
    event.stopPropagation();
  }

  return (
    <>
      <div className={styles.badgePanelContainer}>
        <div className={styles.badgePanelTextContainer}>
          <h3>
            <MakeSuperscript text={data.title} charToWrap="®" />
          </h3>
          <h4>{data.subtitle}</h4>
        </div>
        <div className={styles.badgeContainer}>
          {data.badges.map((badge, index) => {
            if (badge.showElement) {
              return (
                <div
                  className={styles.badge}
                  key={index}
                  role="link"
                  tabIndex="0"
                  onClick={(e) => handleClick(e, badge.badgeLink)}
                  onKeyDown={(e) => handleKeyDown(e, badge.badgeLink)}
                >
                  <div className={styles.imageContainer}>
                    <img src={badge.image.asset.url} alt={badge.title} />
                  </div>
                  <div className={styles.badgeTextWrapper}>
                    <div className={styles.badgeTextContainer}>
                      <h3>
                        <MakeSuperscript text={badge.title} charToWrap="®" />
                      </h3>
                    </div>
                    <div className={styles.buttonContainer} tabIndex={index} role="link" onKeyDown={(e) => buttonClick(e, badge.badgeLink)} onClick={(e) => buttonClick(e, badge.badgeLink)}>
                      <ButtonTemplate data={badge.button}>{badge.button.buttonText}</ButtonTemplate>
                    </div>
                  </div>
                </div>
              )
            } else {
              return null
            }
          })}
        </div>
      </div>
    </>
  )
}

export default BadgePanel
