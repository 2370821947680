// extracted by mini-css-extract-plugin
export var article = "NewsFeed-module--article--7ad13";
export var articleTitle = "NewsFeed-module--articleTitle--2dd64";
export var articleTitleWrapper = "NewsFeed-module--articleTitleWrapper--30622";
export var articles = "NewsFeed-module--articles--9be00";
export var container = "NewsFeed-module--container--3fd41";
export var date = "NewsFeed-module--date--55a93";
export var footer = "NewsFeed-module--footer--6a27a";
export var headerTitle = "NewsFeed-module--headerTitle--84239";
export var imageContainer = "NewsFeed-module--imageContainer--d9f75";
export var textContainer = "NewsFeed-module--textContainer--cfa77";