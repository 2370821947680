// extracted by mini-css-extract-plugin
export var buttonContainer = "ListOneYearPlaceholder-module--buttonContainer--cf7d9";
export var ctaContainer = "ListOneYearPlaceholder-module--ctaContainer--fe07b";
export var ctaSubtitle = "ListOneYearPlaceholder-module--ctaSubtitle--db492";
export var ctaTitle = "ListOneYearPlaceholder-module--ctaTitle--d51ee";
export var descriptionContainer = "ListOneYearPlaceholder-module--descriptionContainer--23d5d";
export var headerTextContainer = "ListOneYearPlaceholder-module--headerTextContainer--7aebe";
export var imageContainer = "ListOneYearPlaceholder-module--imageContainer--679d6";
export var listOneYearContainer = "ListOneYearPlaceholder-module--listOneYearContainer--aa9df";
export var listOneYearContent = "ListOneYearPlaceholder-module--listOneYearContent--36fee";
export var subtitle = "ListOneYearPlaceholder-module--subtitle--c20b1";
export var title = "ListOneYearPlaceholder-module--title--ee57c";