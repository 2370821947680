import React from 'react'
import * as styles from './OrderedListSection.module.scss'
import useMediaQuery from '../../../utils/useMediaQuery'
import slugify from '../../../utils/slugify'
import { PortableText } from '@portabletext/react'

// Renders lists ordered by rank for each category

const OrderedListSection = (props) => {
  const { data, queries, sanityData } = props

  // use to add <br/> on mobile
  const isMobile = useMediaQuery('(max-width: 1400px)')

  // queries for each category, determines the order of the categories
  // const queries = [
  //   'COMMUNITY HOSPITAL WINNERS',
  //   'TEACHING HOSPITAL WINNERS',
  //   'CARDIOVASCULAR TEACHING HOSPITAL WINNERS',
  // ]

  return (
    <section className={styles.container}>
      {queries.map((category, categoryIndex) => {
        const filteredData = data?.filter((item) => item.category === category).sort((a, b) => a.rank - b.rank)

        const middle = Math.ceil(filteredData.length / 2)
        const leftColumn = filteredData.slice(0, middle)
        const rightColumn = filteredData.slice(middle)
        const sanEntry = sanityData.find((sanityCat) => sanityCat.categoryQuery === category)
        // console.log(sanEntry);
        return (
          <div key={categoryIndex} className={styles.categoryContainer} id={slugify(category || '')}>
            <h4 className={styles.category}>{sanEntry?.categoryTitle || category}</h4>
            {/* placeholder description text */}
            {sanEntry?._rawDescription && (
              <div className={styles.description}>
                <PortableText value={sanEntry?._rawDescription} />
              </div>
            )}
            <div className={styles.columnsContainer}>
              <ul>
                {leftColumn.map((item, index) => (
                  <li key={index} className={styles.listItem}>
                    <span className={styles.rank}>{item.rank}. </span>
                    <span>{item.hospital}</span>
                    {item.everest && <span className={styles.everestAward}>*</span>}
                    {isMobile ? <br /> : <span className={styles.dash}> - </span>}
                    <span className={styles.city}>{item.city}</span>
                  </li>
                ))}
              </ul>
              <ul>
                {rightColumn.map((item, index) => (
                  <li key={index} className={styles.listItem}>
                    <span className={styles.rank}>{item.rank}. </span>
                    <span>{item.hospital}</span>
                    {item.everest && <span className={styles.everestAward}>*</span>}
                    {isMobile ? <br /> : <span className={styles.dash}> - </span>}
                    <span className={styles.city}>{item.city}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )
      })}
    </section>
  )
}

export default OrderedListSection
