import React, { useState, useRef, useLayoutEffect } from 'react'
import * as styles from './TopMultiSection.module.scss'
import { PortableText } from '@portabletext/react'
import useMediaQuery from '../../../utils/useMediaQuery'
import { gsap } from 'gsap'
import ButtonTemplate from '../../AppliedSciences/ASComponents/buttonTemplate/buttonTemplate'
import EbookArrow from '../../svg/EbookArrow'


const TempMobileLayout = ({data, solution, customComponents}) => {
  return (
    <div className={styles.container}>
      <h3>{data?.title}</h3>
      <div className={styles.headerDescription}>
        <PortableText value={data?._rawDescription} />
      </div>
      <div className={styles.sectionContainer}>
        <div className={`${styles.solutionBlock} ${styles.active}`}>
          <div className={`${styles.textContainer} ${styles.textMobile}`}>
            <h2>CLINICAL</h2>
            <h2>FINANCIAL</h2>
            <h2>OPERATIONAL</h2>
            <h2>PATIENT EXPERIENCE</h2>
          </div>
          <div className={`${styles.downArrow} ${styles.showArrow}`}></div>
        </div>
        {/* text content appears below each tab on mobile */}
        <div className={styles.solutionExpanded}>
          <div className={styles.solutionTextContainer}>
            <PortableText value={solution._rawSectionDescription} components={customComponents} />
            {solution.button &&
            <div className={styles.buttonContainer + " " + styles.mobileButton}>
              <ButtonTemplate data={solution.button}>
                {solution.button.buttonText}
                <EbookArrow />
              </ButtonTemplate>
            </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

// text content for each panel

const TextContent = (props) => {
  const { solution, index, activeTab, customComponents } = props
  const ref = useRef(null)

  useLayoutEffect(() => {
    // media query
    let mm = gsap.matchMedia()
    // let tween1
    // let tween2

    // desktop only - fade in/out text content
    mm.add('(min-width: 1001px)', () => {
      if (activeTab === index) {
        // tween1 = 
        gsap.fromTo(
          ref.current,
          {
            opacity: 0,
            zIndex: 2,
          },
          {
            duration: 0.5,
            opacity: 1,
            ease: 'power4.out',
            zIndex: 2,
          }
        )
      }
      if (activeTab !== index) {
        // tween2 = 
        gsap.to(ref.current, {
          duration: 0.5,
          opacity: 0,
          ease: 'power4.out',
          zIndex: -1,
        })
      }
    })

    return () => {
      // causing flash and do not see any memeory issues
      // mm.revert('(min-width: 1001px)')
      // tween1?.revert()
      // tween2?.revert()
    }
  }, [activeTab, index, ref])

  if (solution?.sectionTitle || solution?._rawSectionDescription) {
    return (
      <div ref={ref} className={styles.solutionExpanded} key={index}>
        <div className={styles.solutionTextContainer}>
          <h2>{solution.sectionTitle}</h2>
          <PortableText value={solution._rawSectionDescription} components={customComponents} />
          {solution.button &&
          <div className={styles.buttonContainer}>
            <ButtonTemplate data={solution.button}>
              {solution.button.buttonText}
              <EbookArrow />
            </ButtonTemplate>
          </div>
          }
        </div>
      </div>
    )
  }
  return null
}

// main component

const TopMultiSection = (props) => {
  const { data } = props
  // console.log('Top Multi Section Data:')
  // console.log(data)
  const customComponents = {
    marks: {
      footnote: ({ children }) => (
        <span>
          <sup>{children}</sup>
        </span>
      ),
    },
  }
  const [activeTab, setActiveTab] = useState(0)
  const isMobile = useMediaQuery('(max-width: 1000px)')

  const handleHover = (index, action) => {
    if (action === 'enter' && activeTab !== index) {
      setActiveTab(index)
    }
  }
  // console.log(data.sections[2]);
  return (
    <section>
      {!isMobile ? (
        <div className={styles.container}>
          <h3>{data?.title}</h3>
          <div className={styles.headerDescription}>
            <PortableText value={data?._rawDescription} />
          </div>
          <div className={styles.sectionContainer}>
            {data?.sections?.map((solution, index) => {
              if (solution?.sectionTitle || solution?._rawSectionDescription) {
                return (
                  <React.Fragment key={index}>
                    <div
                      className={`${styles.solutionBlock} ${activeTab === index ? styles.active : ''}`}
                      // style={{ backgroundColor: solution.settings.primaryColor }}
                      tabIndex={0}
                      role="button"
                      onMouseEnter={(e) => handleHover(index, 'enter')}
                      onMouseLeave={(e) => handleHover(index, 'leave')}
                      onFocus={(e) => handleHover(index, 'enter')}
                      onBlur={(e) => handleHover(index, 'leave')}
                    >
                      <div className={styles.imageContainer}>
                        <img src={solution.sectionImage.asset.url} alt={solution.sectionImage.asset.title || 'Icon'} />
                      </div>
                      <div className={styles.textContainer}>
                        <h2>{solution.sectionHeader}</h2>
                      </div>
                      <div
                        className={`${styles.downArrow} ${activeTab === index ? styles.showArrow : ''}`}
                        // style={{ borderTopColor: solution.settings.primaryColor }}
                      ></div>
                    </div>
                    {/* text content appears below each tab on mobile */}
                    {isMobile && (
                      <TextContent
                        solution={solution}
                        index={index}
                        activeTab={activeTab}
                        customComponents={customComponents}
                      />
                    )}
                  </React.Fragment>
                )
              }
              return null
            })}
          </div>
          <div className={styles.stacked}>
            {!isMobile &&
              data?.sections?.map((solution, index) => {
                return (
                  <React.Fragment key={index}>
                    <TextContent
                      solution={solution}
                      index={index}
                      activeTab={activeTab}
                      customComponents={customComponents}
                    />
                  </React.Fragment>
                )
              })}
          </div>
        </div>
      ) : (
        <TempMobileLayout data={data} solution={data?.sections[0]} customComponents={customComponents} />
      )}
    </section>
  )
}

export default TopMultiSection
