import React from 'react'

const ArrowRounded = (props) => {
  const { width = '13' } = props

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} fill="none" viewBox="0 0 13 13">
      <path
        fill="#DA437E"
        d="M12.794 7.135l.136-.171v-.069L13 6.792 13 6.036l-.068-.103v-.068l-.137-.172L8.129 1.03a1.029 1.029 0 00-1.44 1.44l2.88 2.916H1.03a1.028 1.028 0 100 2.058h8.54l-2.88 2.881a1.029 1.029 0 101.44 1.442l4.665-4.63z"
      ></path>
    </svg>
  )
}

export default ArrowRounded
